import device from "src/constants/media";
import { colors } from "src/theme";
import styled from "styled-components";

export const ReviewsContainer = styled.div`
  display: flex;
  gap: 16px 24px;
  max-width: 100vw;
  padding: 24px;
  overflow: auto;
  z-index: 1;

  > :last-child {
    padding-right: 24px;
  }

  @media ${device.laptop} {
    margin-top: 12px;
    margin-bottom: -80px;
    flex-wrap: wrap;
    max-width: 720px;
    padding: 0;
    place-content: center;

    > :last-child {
      padding-right: 8px;
    }
  }
`;

export const ReviewContainerLink = styled.a`
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 12px;
  padding: 4px 8px;
  place-items: center;
  width: "fit-content";
  transition: all 200ms ease-in-out;

  :hover,
  :focus {
    background-color: ${colors.mainDark};
  }
`;
