import device from "src/constants/media";
import { colors } from "src/theme";
import styled from "styled-components";

export const ImageGridContainer = styled.div`
  display: grid;
  grid-template-columns: 75px 20px 50vw 75px;
  grid-template-rows: 25px 50px 25px auto 100px;

  @media ${device.laptop} {
    grid-template-columns: repeat(2, 100px) 88px repeat(2, auto) repeat(2, 100px);
    grid-template-rows: repeat(3, 100px) 1fr;
  }
`;

export const AppContainer = styled.div`
  grid-column: 1 / 3;
  grid-row: 3 / 6;
  position: relative;
  height: 265px;
  width: 160px;

  @media ${device.laptop} {
    grid-column: 2 / 5;
    grid-row: 3 / 5;
    height: 500px;
    width: 320px;
  }
`;

export const DashboardContainer = styled.div`
  grid-column: 2 / 5;
  grid-row: 2 / 6;
  position: relative;
  min-width: 400px;

  @media ${device.laptop} {
    grid-column: 4 / 7;
    grid-row: 2 / 5;
    min-width: 800px;
    height: 600px;
  }
`;

export const YorkieContainer = styled.div`
  background-color: ${colors.orange};
  grid-column: 1 / 2;
  grid-row: 5 / 6;
  min-height: 100px;
  min-width: 100px;
  border-radius: 100%;
  overflow: hidden;
  place-self: end;
  z-index: 1;

  @media ${device.laptop} {
    grid-column: 1 / 3;
    grid-row: 2 / 4;
    place-self: center;
    min-height: 100%;
    min-width: 100%;
  }
`;
export const CustomerContainer = styled.div`
  grid-column: 4 / 5;
  grid-row: 1 / 4;
  min-height: 100px;
  min-width: 100px;
  border-radius: 100%;
  overflow: hidden;
  z-index: 1;

  @media ${device.laptop} {
    grid-column: 6 / 8;
    grid-row: 1 / 3;
  }
`;
