import React from "react";
import Image from "next/image";
import {
  ImageGridContainer,
  AppContainer,
  DashboardContainer,
  YorkieContainer,
  CustomerContainer,
} from "./Image.styles";

export const HeroImage: React.FC<{locale?: string}> = ({locale}) => (
  <ImageGridContainer>
    <AppContainer>
      <Image src="/images/Home/app.png" layout="fill" objectFit="contain" />
    </AppContainer>
    <DashboardContainer>
      <Image src="/images/Home/dashboard.png" layout="fill" objectFit="contain" />
    </DashboardContainer>
    <YorkieContainer>
      <Image src="/images/Home/yorkie.png" width={400} height={400} layout="responsive" />
    </YorkieContainer>
    <CustomerContainer>
      <Image
        src={locale === "en-US" ? "/images/customers/dr-donita.png" : "/images/customers/caroline-pennard.png"}
        width={400}
        height={400}
        layout="responsive"
      />
    </CustomerContainer>
  </ImageGridContainer>
);
