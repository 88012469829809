import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";
import { Button, ButtonContainer } from "src/components/ui/button";
// const
import { YOUR_CLINICS_APP_BLOCK } from "src/constants/dynamic";
import { EVariants } from "src/constants/variants";
// hooks
import { useAppSelector } from "src/hooks";
// libs
import { amplitudeEvent, formatEventName } from "src/libs/amplitude";
// redux
import { dynamicStateFieldSelector } from "src/redux/slices";
// theme
import { colors } from "src/theme";
import { Callout, Jumbo } from "src/theme/typography";
// types
import { IDynamicYourClinicsApp } from "src/types/dynamic/clinics/yourClinicsApp";
// styled-components
import { Content } from "./Hero.styles";
import { HeroImage } from "./Image";
import { Reviews } from "./Reviews";

const MainIcon: React.FC = () => {
  const data = useAppSelector(
    dynamicStateFieldSelector<IDynamicYourClinicsApp>(YOUR_CLINICS_APP_BLOCK),
  );
  const { pathname, locale } = useRouter();

  return (
    <Content>
      <Jumbo textColor={colors.white} style={{ maxWidth: 1096 }}>
        {data?.title}
      </Jumbo>
      <Callout textColor={colors.mainLight} style={{ maxWidth: 720 }}>
        {data?.text}
      </Callout>
      <ButtonContainer style={{ marginTop: 12 }}>
        <Link href="/demo">
          <Button
            variant={EVariants.SECONDARY}
            onClick={() => amplitudeEvent(formatEventName(pathname, "hero", "book_demo"))}
          >
            {locale === "en-US" ? "Schedule" : "Book"} a call
          </Button>
        </Link>
        {/* <a href="https://meeting.petsapp.com/contact-petsapp">
          <Button
            variant={EVariants.QUATERNARY}
            onClick={() => amplitudeEvent(formatEventName(pathname, "hero", "contact_us"))}
          >
            Talk to us
          </Button>
        </a> */}
      </ButtonContainer>
      <Reviews />
      <HeroImage locale={locale} />
    </Content>
  );
};

export default MainIcon;
