import device from "src/constants/media";
import styled from "styled-components";

export const Content = styled.header`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 24px;
  padding-inline: 24px;
  place-items: center;
  position: relative;
  text-align: center;

  @media ${device.mobileM} {
    margin-top: 40px;
  }

  @media ${device.laptop} {
    margin-top: 80px;
  }
`;
